// version from `meta.json` - first param
// version in bundle file - second param
import {useEffect, useState} from "react";
import useLoadingSystem from "./LoadingPage/useLoadingSystem";

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

const CacheBuster = (props) => {
    let [loader, stopLoading] = useLoadingSystem(true)
    let [isLatestVersion, setLatestVersion] = useState(false)
    let {children} = props

    useEffect(() => {
        (async () => {
            fetch("/meta.json")
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = global.appVersion;

                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        console.log(`We have a new version - ${latestVersion}. Should force refresh`);

                        stopLoading()
                        setLatestVersion(false)
                    } else {
                        console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                        stopLoading()
                        setLatestVersion(true)
                    }
                });
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const refreshCacheAndReload = () => {
        console.log("Clearing cache and hard reloading...")
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    }
    if (loader) return null;
    if (!loader && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
    }
    return children;
}

export default CacheBuster
