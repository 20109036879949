import {createTheme} from "@mui/material";

export const createAppStore = () => {


    return {
        isDisconnected:
            {
                get: false,
                set(data) {
                    this.get = data;
                },
            },
        theme: {
            get: createTheme(options),
            options: options,
            getDefaultTheme() {
                return createTheme(options)
            },
            set() {
                let palette, overrides
                localStorage.setItem("theme", this.styleTmp)
                switch (this.styleTmp) {
                    case "light":
                        palette = paletteLight
                        overrides = overridesLight
                        break
                    case "dark":
                    default:
                        palette = paletteDark
                        overrides = overridesDark
                        break
                }
                this.get = createTheme({
                    ...this.options,
                    components: {...this.options.components, ...overrides},
                    palette: palette
                })
            },
            style: localStorage.getItem("theme") || "dark",
            setStyle(data) {
                this.style = data
            },
            styleTmp: localStorage.getItem("theme") || "dark",
            setStyleTmp(data) {
                this.styleTmp = data
            }
        },
        appLoading: {
            get: true,
            set(data) {
                if (data !== this.get) {
                    this.get = data;
                }
            },
        },
        loading: {
            get: true,
            set(data) {
                if (data !== this.get) {
                    this.get = data;
                }
            },
        },
        localLoading: {
            get: true,
            set(data) {
                if (data !== this.get) {
                    this.get = data;
                }
            },
        },
        localLoadingLite: {
            get: false,
            set(data) {
                if (data !== this.get) {
                    this.get = data;
                }
            },
        },
    }
}


const defaultTheme = createTheme();

export const paletteDark = {
    mode: 'dark',
    background: {
        default: "#2E2E2E",
        section1: "#4A4A4A",
        section2: "#5C5C5C",
        mainSection: "#3A3A3A",
        torrentCard: "#4A4A4A",
        paper: "#3F3F3F",
        paper2: "#4A4A4A",
        settingsItem: "#5C5C5C"
    },
    text: {
        text: {
            primary: "#FFFFE0", // light yellow
            secondary: "#FFD700", // gold
        darkContrast: "#2E2E2E",
            paragraph: "#FFFFE0" // light yellow
        },
    },
    primary: {
        main: "#FFA500", // orange
        contrastText: "#2E2E2E",
    },
    secondary: {
        main: "#FFD700", // gold
        contrastText: "#2E2E2E",
    },
    tertiary: {
        main: "#FF4500", // orange-red
        contrastText: "#2E2E2E",
    },
    success: {
        main: "#FFD700", // gold
        contrastText: "#2E2E2E",
    },
    info: {
        main: "#FFA500", // orange
        contrastText: "#2E2E2E",
    },
    warning: {
        main: "#FF8C00", // dark orange
        contrastText: "#2E2E2E",
    },
    error: {
        main: "#FF6347", // tomato
        contrastText: "#2E2E2E",
    },
    button: {
        main: "#FFA500", // orange
        hover: "#FFD700" // gold
    },
    disabled: {
        main: "#5C5C5C",
        contrastText: "#FFA500" // orange
    },
    border: {main: "#4A4A4A"}
}
export const paletteLight = {
    mode: 'light',
    background: {
        section1: "#f0f7ff",
        section2: "#e6f2ff",
        default: "#c1cee3",
        mainSection: "#fff",
        torrentCard: "#E5EEFF",
        paper: "#FFF",
        paper2: "#f8fafe",
        settingsItem: "#6C77DA",
    },
    text: {
        primary: "#010101",
        secondary: "#A1A1A1",
        contrast: "#fff",
        darkContrast: "#000",
        paragraph: "#45384a"
    },
    primary: {
        main: "#eec8ea",
        contrastText: "#45384a",
    },
    secondary: {
        main: "#77DD77", // pastel green
        contrastText: "#45384a",
    },
    tertiary: {
        main: "#99A1EC",
        contrastText: "#fff",
    },
    success: {
        main: "#A6F1C9",
        contrastText: "#fff",
    },
    info: {
        main: "#A6C0F1",
        contrastText: "#fff",
    },
    warning: {
        main: "#FEEACC",
        contrastText: "#fff",
    },
    button: {
        main: "#010101",
        hover: "#afafb0"
    },
    disabled: {
        main: "#707577",
        contrastText: "#012244"
    },

    border: {main: "#c7cdd2"}
}
export const overridesDark = {
    MuiTab: {
        root: {
            padding: "0px"
        }
    },

    MuiLinearProgress: {
        styleOverrides: {
            root: {
                borderRadius: "10px"
            }
        }
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                marginTop: "7px",
            },
            primary: {
                fontSize: "14px",
                fontWeight: 100,
            }
        }
    },

    MuiTableContainer: {
        styleOverrides: {
            root: {
                height: "100%",
            },
        },
    },

    MuiContainer: {
        styleOverrides: {
            root: {
                overflow: "hidden",
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "100%",
                [defaultTheme.breakpoints.up('xs')]: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "5px",
                }
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                // backgroundColor: paletteDark.background.paper2,
                padding: 0,
                borderRadius: "16px",
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },

            },
            input: {
                height: "70%!important",
                paddingLeft: "32px",
                paddingRight: "32px",
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            }
        }
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: "40px"
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                minWidth: "100%",
                padding: "0 40px"
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: "40px",
                justifyContent: "center",
                gap: "8px"
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "unset",
                fontSize: "14px",
                fontWeight: 100,
                boxShadow: "none",
                padding: "6px 30px",
                borderRadius: "16px"
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: "24px"
            }
        }
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                background: "#161818",
                padding: "10px 10px",
                fontSize: "14px",
                fontWeight: 100
            },
        },
    }, MuiPopover: {
        styleOverrides: {
            paper: {
                backgroundColor: paletteDark.background.default + "!important",
                borderRadius: "10px"
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    // backgroundColor: "transparent!important"
                },

            }
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    // backgroundColor: "transparent!important"
                },
                "&:hover": {
                    backgroundColor: "#555!important"
                }
            },

        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            notchedOutline: {
                border: "1px solid #262626",
                borderColor: "#262626!important",
            },
            root: {
                "&.Mui-disabled": {
                    border: "1px solid #262626",

                },
                // padding: 0,
                // paddingRight: "30px",
                borderRadius: "16px!important",
                height: "54px",
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },

            },
            multiline: {
                // paddingTop: "10px!important",
            },
            input: {
                // padding: 0,

                height: "100%!important",
                alignItems: "center",
                paddingLeft: "32px",
                display: "flex"
            },
        }
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: "#F8FAFE"
            }
        }
    }
}
export const overridesLight = {
    MuiTab: {
        styleOverrides: {
            textColorPrimary: {
                // padding: "0px",
                //color: "white!important"
            },

        }
    },
    MuiLinearProgress: {
        styleOverrides: {
            root: {
                borderRadius: "10px"
            }
        }
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                marginTop: "7px",
            },
            primary: {
                fontSize: "14px",
                fontWeight: 100,
            }
        }
    },

    MuiTableContainer: {
        styleOverrides: {
            root: {
                height: "100%",
            },
        },
    },

    MuiContainer: {
        styleOverrides: {
            root: {
                overflow: "hidden",
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "100%",
                [defaultTheme.breakpoints.up('xs')]: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "5px",
                }
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: "40px"
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                minWidth: "100%",
                padding: "0 40px"
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: "40px",
                justifyContent: "center",
                gap: "8px"
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "unset",
                fontSize: "14px",
                fontWeight: 100,
                boxShadow: "none",
                padding: "6px 30px",
                borderRadius: "16px"
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                borderRadius: "24px"
            }
        }
    },

    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: "#292929"
            }
        }
    },

    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                background: "#867979",
                padding: "10px 10px",
                fontSize: "14px",
                fontWeight: 100

            },
        },
    }, MuiPopover: {
        styleOverrides: {
            paper: {
                background: "#fff"
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: paletteLight.background.default + "!important"
                },
                "&:hover": {
                    backgroundColor: paletteLight.background.default + "!important"
                },
                backgroundColor: paletteLight.background.paper2 + "!important"
            }
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                // backgroundColor: paletteLight.background.paper2,
                "&.Mui-selected": {
                    // backgroundColor: paletteLight.background.default
                },
                "&:hover": {
                    backgroundColor: "#6e82a6!important",
                    color: "#fff"
                }
            },

        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            notchedOutline: {
                border: "1px solid #E4ECF6",
                borderColor: "#E4ECF6!important",
            },
            root: {
                backgroundColor: paletteLight.background.mainSection,
                "&.Mui-disabled": {
                    border: "1px solid #E4ECF6",
                },
                color: "black",
                padding: 0,
                paddingRight: "30px",
                borderRadius: "16px!important",
                height: "54px",
                border: "1px solid #E4ECF6",
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },
            },
            multiline: {
                paddingTop: "10px!important",
            },
            input: {
                padding: 0,
                height: "100%!important",
                alignItems: "center",
                paddingLeft: "32px",
                display: "flex"
            },
        }
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                backgroundColor: paletteLight.background.mainSection,
                padding: 0,
                borderRadius: "16px",
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                '& input[type=number]': {
                    MozAppearance: 'textfield',
                },

            },
            input: {
                height: "70%!important",
                paddingLeft: "32px",
                paddingRight: "32px",
            },
            underline: {
                "&&&:before": {
                    borderBottom: "none"
                },
                "&&:after": {
                    borderBottom: "none"
                }
            }
        }
    },
}


const options = {
    palette: ((localStorage.getItem("theme") && localStorage.getItem("theme") === "dark")) ? paletteDark : paletteLight,
    components: {

        ...((localStorage.getItem("theme") && localStorage.getItem("theme") === "dark")) ? overridesDark : overridesLight,
    },
};
