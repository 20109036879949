import React, {useState} from "react";
import {Skeleton} from "@mui/material";
import LoadingPage from "./LoadingPage";


const useLoadingSystem = (startLoading, isSkeleton = {enabled: false, height: undefined, width: "100%"}, timeOutTime = 0,customSentence) => {

    let [loading, setLoading] = useState(startLoading || false)

    return [
        loading ? isSkeleton && isSkeleton.enabled ? <Skeleton
            variant={isSkeleton.variant || "rectangular"}
            width={isSkeleton.width || "100%"}
            sx={{height: isSkeleton.height|| "50px"}}
        /> : <LoadingPage
            customSentence={customSentence}
        /> : false,
        () => {

                setTimeout(() => {
                    setLoading(false)
                }, timeOutTime)
        },
        () => {

                setLoading(true)


        },


    ]
}

export default useLoadingSystem