import React, {Component} from 'react';
import {Button, Typography} from "@mui/material";
import {Error} from "@mui/icons-material";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("Error in boundary", error, errorInfo);
    }

    render() {
        // You can render any custom fallback UI
        if (this.state.hasError) {
            return <div style={{
                height: "60px",
                background: "#ce1f22",
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                left: 0,
                width: "100%",
                zIndex: 1
            }}>
                <Error fontSize={"large"} style={{color: "var(--white-text)", marginRight: "20px"}}/>
                <Typography variant={"title"}>
                    Something went wrong, try to
                </Typography>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    sx={{marginLeft:"10px"}}
                    onClick={()=>{
                    window.location.reload();
                    }}
                >
                    RELOAD
                </Button>
            </div>
        }
        return this.props.children


    }
}