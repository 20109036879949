import {CssBaseline, ThemeProvider} from "@mui/material";
import React, {useEffect} from "react";
import BaseRouter from "./routing/BaseRouter";

import {observer, Observer} from "mobx-react";
import {BrowserRouter} from "react-router-dom";
import CacheBuster from "./CacheBuster";
import packageJson from "../package.json"
import ErrorBoundary from "./ErrorBoundary";
import NetworkDetector from "./NetworkDetecter";
import {useAppStore} from "./MobX/AppContext";

global.appVersion = packageJson.version

if (process.env.REACT_APP_NODE_ENV === "production") {
    console.info = () => {
    };
    console.debug = () => {
    };
    console.log = () => {
    };
    console.group = () => {
    };
    console.groupEnd = () => {
    };
    console.track = {
        trackEvent: () => {
        }
    };
}


const App = () => {
    let store = useAppStore();
    let {language, theme, localLoading} = store
    useEffect(() => {
        (async () => {
            window.TNDglobalStoreMobx = store
            localLoading.set(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<CacheBuster>
            <ThemeProvider theme={theme.get} key={theme.get}>
                <CssBaseline/>
                <ErrorBoundary>
                    <NetworkDetector>
                        <Observer>{() => (
                            <>
                                <BrowserRouter basename={process.env.PUBLIC_URL}>
                                    <BaseRouter
                                        store={store}
                                    />
                                </BrowserRouter>
                            </>
                        )}
                        </Observer>
                    </NetworkDetector>
                </ErrorBoundary>
            </ThemeProvider>
        </CacheBuster>
    );

}


export default observer(App);

