import React, {useEffect} from "react";
import {CloudOff} from "@mui/icons-material";
import {observer} from "mobx-react";
import {Typography} from "@mui/material";
import {useAppStore} from "./MobX/AppContext";

const NetworkDetector = (props) => {

    let {children,} = props;
    let store = useAppStore()
    let {isDisconnected} = store

    useEffect(() => {

        const handleConnectionChange = () => {
            const condition = navigator.onLine ? 'online' : 'offline';
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('//google.com', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                isDisconnected.set(false)
                                return clearInterval(webPing)

                            }).catch(() => {
                            isDisconnected.set(true)
                        })
                    }, 2000);
                return
            }
            return isDisconnected.set(true)
        }
        (async () => {
            handleConnectionChange()
            window.addEventListener('online', handleConnectionChange);
            window.addEventListener('offline', handleConnectionChange);
        })()

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                isDisconnected.get
                // true
                && (<div style={{
                    height: "60px",
                    background: "#dcbd54",
                    top: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    zIndex: 1
                }}>
                    <CloudOff fontSize={"large"} style={{color: "var(--white-text)", marginRight: "20px"}}/>
                    <Typography variant={"title"}>
                        No internet connection
                    </Typography>
                </div>)
            }
            {children}
        </>
    );

}

export default observer(NetworkDetector)
