// import style from "../../index.module.css";
// import QuixLogoMobile from "../../asset/image/quixLogo512.png";
import {Stack, Typography, useMediaQuery} from "@mui/material";

const LoadingPage = ({customSentence}) => {
    let isMobile = useMediaQuery('(max-width:868px)');
    return <Stack maxWidth="false" sx={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        backdropFilter: "blur(4px) brightness(0.5)",
        zIndex: 101000,
    }}>
        <Typography
            sx={{
                position: "absolute",
                top: "40%",
                left: "20px",
                right: "20px",
                marginLeft: "auto",
                marginRight: "auto",
            }}
            textAlign={"center"}
            variant={"title"}>{customSentence}</Typography>
        {/*<img*/}
        {/*    style={{*/}
        {/*        zIndex: 102,*/}
        {/*        position: "absolute",*/}
        {/*        top: customSentence && isMobile ? "60%" : "50%",*/}
        {/*        left: 0,*/}
        {/*        right: 0,*/}
        {/*        marginLeft: "auto",*/}
        {/*        marginRight: "auto",*/}
        {/*        height:"150px",*/}
        {/*        width:"150px"*/}
        {/*    }}*/}
        {/*    className={style.rotate}*/}
        {/*    alt={"quix-logo"} src={QuixLogoMobile}/>*/}
    </Stack>
}

export default LoadingPage