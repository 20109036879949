import {useMediaQuery} from "@mui/material";
import {observer} from "mobx-react";
// import {useIntl} from "react-intl";
// import useSnackbar from "../../components/snackbar/useSnackbar";
import {useCallback, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";


export const withCommonPropsLite = (WrappedComponent) => {

    let MainComponent = observer(WrappedComponent);
    return (props) => {
        let history = useHistory();
        let match = useRouteMatch();
        let location =history.location
        let store = window.TNDglobalStoreMobx || props.store;
        let searchValues = window.TNDsearchValues && window.TNDsearchValues(history.location.search);
        let isMobile = useMediaQuery('(max-width:868px)');
        return <MainComponent
            {...{
                ...props,
                store,
                history,
                match,
                location,
                searchValues,
                isMobile
            }} />
    }
}


export const withCommonProps = (WrappedComponent) => {

    let MainComponent = observer(WrappedComponent);

    return (props) => {
        let isDesktop = useMediaQuery('(min-width:1600px)');
        let isTablet = useMediaQuery('(min-width:869px)') && !isDesktop;
        let isMobile = useMediaQuery('(max-width:868px)');

        // let isDesktop=true
        //  let isTablet = false
        //  let isMobile = false


        // let bigBar = isDesktop;
        // let hideBar = useMediaQuery('(max-width:1200px)');
        // let smallBar = useMediaQuery('(max-width:1600px)') && !hideBar;

        let history = useHistory();
        let match = useRouteMatch();
        let location =history.location
        let store = window.TNDglobalStoreMobx;
        let searchValues = window.TNDsearchValues && window.TNDsearchValues(history.location.search);


        // let intl = useIntl()

        // let [snackBar, showSnackbar] = useSnackbar()
        const [, updateState] = useState();
        const forceUpdate = useCallback(() => updateState({}), []);

        return <>
            {/*{snackBar}*/}
            <MainComponent
                {...{
                    ...props,
                    store,
                    history,
                    match,
                    searchValues,
                    location,
                    isDesktop,
                    isTablet,
                    isMobile,
                    forceUpdate,
                }} /></>

    };

};
