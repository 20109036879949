import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import React, {Suspense, useEffect} from "react";
import queryString from "query-string";
import LoadingPage from "../LoadingPage/LoadingPage";
import routes from "./routes.json";

import DefaultLoading from "../LoadingPage/DefaultLoading";
import {withCommonPropsLite} from "../interfacehook/InterfaceHook";


export const BaseRouter = (props) => {
    let history = useHistory();
    let match = useRouteMatch();
    let {store} = props
    let {appLoading, loading} = store

    let searchValues = (search) => {
        return queryString.parse(search)
    }
    if (!window.TNDhistory) {
        window.TNDhistory = history
    }
    if (!window.TNDmatch) {
        window.TNDmatch = match
    }
    if (!window.TNDlocation) {
        window.TNDlocation = history.location
    }
    if (!window.TNDsearchValues) {
        window.TNDsearchValues = searchValues
    }


    useEffect(() => {
        (async () => {


        })()

        appLoading.set(false)
        loading.set(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (appLoading.get) {
        return <LoadingPage/>
    }


    return (<>
            <Suspense
                fallback={<LoadingPage/>}
            >
                {<DefaultLoading/>}
                <Switch>
                    {routes?.routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            component={React.lazy(() => import(`../screen/${route.name}`))}
                        />
                    ))}
                </Switch>
            </Suspense>
        </>
    )
}

export default withCommonPropsLite(BaseRouter)
