import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from "./App";
import './asset/elucidCircularA/index.css';


import {AppProvider} from "./MobX/AppContext";
import {PostHogProvider} from 'posthog-js/react'

const container = document.getElementById('root');
const root = createRoot(container);
const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

root.render(<AppProvider><PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
><App/></PostHogProvider></AppProvider>);

